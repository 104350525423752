/*******************************
            Theme
*******************************/

/*
  The type is the type of the component as defined in semantic-ui - element, collection, view, module
  For simple component, 'element' is the correct type
*/
@type: 'element';

/*
  This is the name of the component - though not required, keep it the same as the filename
*/
@element: 'separated';

/*******************************

    DO NOT FORGET !!!

Edit *theme.config* in your site 
and add (replace 'sample' with the
name of the component from above)

@sample : 'default';

*******************************/

@import (multiple) '../../theme.config';

/*
  This is the styling of your component. The class should start with .ui.<component-name>

  Note: use variables here, not the exact values. Placing the values to another file (sample.variables)
  will allow users to override these values.
 */
.ui.separated {
  align-items: center;
  line-height: 2;

  .double.separator {
    display: inline-block;
    width: 0.5rem;
    margin: 0 0.6rem -0.4em 0.6rem;
    height: 1.5rem;
    border-left: 1.5px solid @green;
    border-right: 1.5px solid @green;
  }

  .list > .item:nth-child(n + 2) {
    border-left: 1.5px solid @green;
    padding-left: 0.5rem;
    margin-left: 0.3rem;
  }
}

/*
  This will load the overrides, enabling the user of your component to override your css if required
 */
.loadUIOverrides();
